.rootcode-progruz.full-height {
  height: 100vh;
  background-color: #e3e9ee;

  .authorization {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .auth-form {
    background-color: #263238;
    width: 398px;
    padding: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: $white;
      gap: 8px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      text-align: center;
      font-size: 18px;
      color: $white;

      &__error {
        color: #e36b6c;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__field {
      background-color: $white;

      &__label {
        font-size: 18px;
        background-color: transparent;
        color: $grey;

        &.error,
        &.error .required {
          color: #e36b6c;
        }
      }

      &__label .required {
        color: $red;
      }

      &__additionalText {
        display: grid;
        grid-template-columns: 16px auto;
        gap: 4px;
        font-size: 14px;
        line-height: 16.8px;

        color: #a0a0a0;
        svg path {
          fill: #a0a0a0;
        }

        &.error {
          color: #e36b6c;
        }

        &.error svg path {
          fill: #e36b6c;
        }
      }

      &__icon {
        width: 24px;
        height: 24px;
      }

      &__icon.show-password {
        cursor: pointer;
      }
    }

    &__field,
    &__field::placeholder {
      font-size: 16px;
      color: $grey;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__submit {
      width: 100%;
      height: 48px;
      background-color: $red;

      &:disabled {
        background-color: #8a231d;
      }

      &:hover {
        background-color: #e84848;
      }
    }

    &__additional-actions {
      display: flex;
      justify-content: space-between;
    }

    .checkbox__label {
      font-size: 16px;
      color: $white;
    }

    .forgot-password {
      font-size: 14px;
      color: #a0a0a0;
      border-bottom: 1px solid #a0a0a0;
      padding: 6px 0 6px 0;
    }
  }
}
