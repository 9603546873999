.routesContainer {
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.mainContainer {
  display: flex;
  position: relative;
}
