.blink{
  text-align: center;
}
.blink > span{
  color: white;
  animation: blink 1s linear infinite;
}

/* .infoicon{
  color: #72bccb;
}

.blink .infoicon {
  color: #e8d715;
  animation: blink 1s linear infinite;
} */

@keyframes blink{
60%{opacity: .6;}
100%{opacity: 1;}
60%{opacity: .6;}
}