.rootcode-progruz {
  .alerts {
    position: absolute;
    max-width: 60%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2%;
    z-index: 10000000;
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &__body {
      padding: 12px 16px;
      color: white;
      border-radius: 8px;
      text-align: center;
      background-color: #e72a36;
      &.success {
        background-color: #2ed47a;
      }
    }
  }
}
