.home-page {
  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 1.5rem;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
}