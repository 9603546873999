$sidebar-bg-color: #1f292e !default;
$sidebar-color: #bbbbbb !default;
$sidebar-width: 300px !default;
$sidebar-collapsed-width: 0px !default;
$highlight-color: #ffffff !default;
$submenu-bg-color: #1b2328 !default;
$submenu-bg-color-collapsed: #1b2328 !default;
$icon-bg-color: #1c2327 !default;
$icon-size: 15px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

// .pro-sidebar {
//   position: absolute;
//   transform: translate(0,0);
//   &.collapsed {
//     transform: translate(-100%,0);
//   }
// }
