.rootcode-progruz {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($black, 0.2);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear;

    &.active {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    &__container {
      width: 100%;
      height: 100%;
      padding: 20px 0;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: $grey;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100%;
    }

    &__wrapper {
      display: inline-block;
    }
  }

  .form-modal {
    width: 700px;
    margin: 0 auto;
    padding: 16px;
    border-radius: 8px;
    background-color: $white;

    &__title {
      color: $black;
      font-family: Manrope;
      font-size: 24px;
      font-weight: 500;
      line-height: 100%; /* 24px */
      letter-spacing: -0.72px;
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
      margin-bottom: 30px;
      transition: 0.2s;
      &.error {
        border: 1px solid rgba(250, 97, 97, 0.685);
      }
    }

    &__subtitle {
      color: $dark;
      font-family: Manrope;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.48px;
      margin-bottom: 12px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
      position: relative;
      margin-bottom: 55px;
      // &:not(:last-child) {
      // margin-bottom: 18px;
      // }
    }

    &__grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      gap: 16px;
    }

    &__select,
    .prediction-form {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 3;
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 6px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid $gray;
        border-radius: 50%;
        transition: 0.2s;
      }

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $blue;
        opacity: 0;
        transition: 0.2s;
      }

      &.checked {
        &::before {
          border-color: $blue;
        }
        &::after {
          opacity: 1;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 32px;
      gap: 16px;
    }

    .prediction-form {
      border: 1px solid $gray;
      border-radius: 8px;
      width: 300px;
      &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 10px;
        color: #000;
        font-family: Manrope;
        font-size: 15px;
        font-weight: 400;
        line-height: 115%; /* 17.25px */
        letter-spacing: -0.3px;
        position: relative;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          border-radius: 50%;
          border: 1px solid #d9e0e5;
        }
        &::after {
          content: '';
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $blue;
          opacity: 0;
          transition: 0.2s;
        }

        &.checked {
          &::before {
            border-color: $blue;
          }
          &::after {
            opacity: 1;
          }
        }
        &:first-child {
          border-bottom: 1px solid $gray;
        }
      }
      &__options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow: auto;
        border-radius: 8px;
        border: 1px solid $gray;
        background: $white;
        padding: 4px;
      }
      &__option {
        padding: 8px;
        border-radius: 8px;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        cursor: pointer;
        transition: 0.2s;
        @media (any-hover: hover) {
          &:hover {
            background: $gray;
          }
        }
        &.selected {
          background: $gray;
        }
      }
    }
  }

  .confirm-modal {
    padding: 20px;
    text-align: center;
    min-width: 424px;
    background-color: $white;
    border-radius: 8px;

    &__title {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 40px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .display-parameters-modal {
    min-width: 1121px;

    .checkbox.parameter {
      height: 51px;
      padding: 4px 16px;
      border: 1px solid $gray;
      border-radius: 8px;
    }

    .checkbox.parameter.disabled {
      background-color: #f4f4f4;
    }

    .form-modal {
      &__item {
        gap: 16px;

        &:not(:last-child) {
          margin-bottom: 32px;
        }

        &__header {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }
      }

      &__subtitle {
        margin-bottom: 0;
      }
    }
  }
}
