.menu-versions {
  padding-left: 8px;
  padding-bottom: 20px;
  font-family: 'Roboto';
  font-size: 14px;
}

.menu-versions__item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.menu-versions__item:first-child {
  margin-bottom: 10px;
}

.menu-versions__date {
  opacity: 0.6;
}

.pro-icon-wrapper {
  display: block !important;
}

.pro-inner-item::before {
  display: none !important;
}

a::before {
  display: none !important;
}

a:hover {
  text-decoration: none !important;
  transition: all 0.1s ease-in-out !important;
  /*color: #ff7300 !important;*/
  color: #eb2805;
}

a {
  cursor: pointer !important;
}

.dropDownMenu:hover {
  text-decoration: none !important;
  transition: all 0.1s ease-in-out !important;
  /*color: #ff7300 !important;*/
  color: #eb2805;
}

.dropDownMenu {
  cursor: pointer !important;
}

.dropDown:hover {
  text-decoration: none !important;
  transition: all 0.1s ease-in-out !important;
  /*color: #ff7300 !important;*/
  color: #fff;
  background-color: #eb2805;
}

.dropDown {
  cursor: pointer !important;
  color: inherit !important;
}

@media (min-width: 992px) {
  .modal-fluid.filters {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .modal-lg.report {
    width: 1400px !important;
    max-width: 1400px !important;
  }
}

@media (min-width: 0px) {
  .modal-fluid.objmodal {
    /*width: calc(100% - 300px) !important;*/
    width: 100% !important;
    z-index: 9999 !important;
  }
}

@media (min-width: 0px) {
  .modal-lg.objmodal {
    width: 400px !important;
    z-index: 9999 !important;
  }
}

.modal-full-height .modal-lg .modal-right .modal-dialog .objmodal {
  z-index: 9999 !important;
}

body.modal-open {
  overflow: hidden !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.side.nav {
  background-color: #cecece;
}

.side-nav.wide {
  width: 300px;
}

.side-nav.wide .collapsible a {
  padding-left: 10px;
}

.side-nav .logo-wrapper a {
  height: 180px;
  margin: auto;
}

.side-nav .logo-wrapper {
  height: 180px;
  margin-top: -5px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  white-space: normal !important;
  /*text-align: justify !important;*/
}

.custom-container {
  padding-top: 1.25rem;
  padding-left: 1rem;
}

.dictionary-page_limited-width {
  width: calc(100% - 1rem);
}

.heading-wrapper {
  padding-left: 15px;
  padding-bottom: 10px;
}

.scrollbar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.scrollbar-primary-table {
  scrollbar-color: #eeeeee #dfdfdf;
}
.scrollbar-primary-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ececec;
}

.scrollbar-primary-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #b4b4b4;
}

.scrollbar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.scrollbar-map {
  scrollbar-color: #eeeeee #dfdfdf;
}
.scrollbar-map::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ececec;
}

.scrollbar-map::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #b4b4b4;
}

.table-dropdown {
  z-index: 3000 !important;
}

.input-distance {
  margin: 0px;
  border-color: #4285f4 !important;
}

.background-main {
  background: #252a2a;
}

@font-face {
  font-family: 'Halvar Breitschrift Bold';
  src: local('Halvar Breitschrift'), url(assets/fonts/TypeMates\ -\ Halvar\ Breitschrift\ Bold.ttf);
}
@font-face {
  font-family: 'Halvar Breitschrift Regular';
  src: local('Halvar Breitschrift Regular'), url(assets/fonts/TypeMates\ -\ Halvar\ Breitschrift\ Regular.ttf);
}
@font-face {
  font-family: 'Halvar Mittelschrift Regular';
  src: local('Halvar Mittelschrift Regular'), url(assets/fonts/TypeMates\ -\ Halvar\ Mittelschrift\ Regular.ttf);
}
@font-face {
  font-family: 'Halvar Mittelschrift Bold';
  src: local('Halvar Mittelschrift Bold'), url(assets/fonts/TypeMates\ -\ Halvar\ Mittelschrift\ Bold.ttf);
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #faa1a1;
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #eb2805;
}

.leaflet-control-layers-list [type='radio']:not(:checked),
.leaflet-control-layers-list [type='radio']:checked,
.leaflet-control-layers-list [type='checkbox']:not(:checked),
.leaflet-control-layers-list [type='checkbox']:checked,
.leaflet-control-layers-list input[type='checkbox'],
.leaflet-control-layers-list input[type='radio'] {
  position: relative;
  opacity: 1;
  cursor: 'pointer';
}

.leaflet-control-layers-base label div span {
  cursor: 'pointer' !important;
}

.leaflet-control-layers-selector {
  cursor: 'pointer' !important;
}

label {
  cursor: 'pointer' !important;
}

.leaflet-top.leaflet-left {
  z-index: 555 !important;
}

.leaflet-bottom {
  z-index: 555 !important;
}

.legend {
  z-index: 444 !important;
}

.collapse-separate {
  border-collapse: separate !important;
}
.MuiTable-root {
  border-collapse: inherit !important;
}

.MuiTableRow-root[mode='update'] .MuiTableCell-root {
  border-top: 1px solid #e5e5e5;
}

.MuiTableRow-root[mode='delete'] .MuiTableCell-root {
  border-top: 1px solid #e5e5e5;
}

.collapse-separate .MuiTable-root tbody tr td:first-child {
  background-color: #f2f4f6 !important;
  /* box-shadow: 6px 0px 5px rgba(34, 60, 80, 0.15); */
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.collapse-separate .MuiTable-root tbody tr td:only-child {
  background-color: #fff !important;
}

.collapse-separate .MuiTable-root thead tr:nth-child(1) th:first-child {
  background-color: #f2f4f6 !important;
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 11 !important;
}

.collapse-separate-view {
  border-collapse: separate !important;
}

.collapse-separate-view .MuiTable-root tbody tr td:first-child {
  background-color: #ffffff !important;
  /* box-shadow: 6px 0px 5px rgba(34, 60, 80, 0.15); */
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.collapse-separate-view .MuiTable-root tbody tr td:only-child {
  background-color: #fff !important;
}

.collapse-separate-view .MuiTable-root thead tr:nth-child(1) th:first-child {
  background-color: #f2f4f6 !important;
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 11 !important;
}

.collapse-separate-menu {
  border-collapse: separate !important;
}

.collapse-separate-menu .MuiTable-root tbody tr td:first-child {
  background-color: #ffffff !important;
  /* box-shadow: 6px 0px 5px rgba(34, 60, 80, 0.15); */
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.collapse-separate-menu .MuiTable-root tbody tr td:only-child {
  background-color: #fff !important;
}

.collapse-separate-menu .MuiTable-root thead tr:nth-child(1) th:first-child tr:nth-child(2) th:first-child {
  background-color: #f2f4f6 !important;
  border-right: 1px solid #e5e5e5 !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 11 !important;
}

.transparent-btn {
  width: 100px;
  height: 50px;
  background-color: transparent !important;
  color: #fff !important;
  border: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: none;
  cursor: pointer;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  overflow-y: auto !important;
  scroll-behavior: smooth !important;
  scrollbar-color: #eeeeee #dfdfdf !important;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded::-webkit-scrollbar {
  width: 9px;
  height: 10px;
  background-color: #f3f3f3;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #b4b4b4;
}

.MuiTableFooter-root.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  position: absolute !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: #bebebe;
}

.MuiPaper-root.MuiPaper-rounded.MuiPaper-rounded > :nth-child(2) > :first-child > :first-child {
  overflow-y: scroll !important;
  scroll-behavior: smooth !important;
  scrollbar-color: #eeeeee #dfdfdf !important;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded :nth-child(2) > :first-child > :first-child::-webkit-scrollbar {
  width: 11px;
  height: 10px;
  background-color: #f3f3f3;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded :nth-child(2) > :first-child > :first-child::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #b4b4b4;
}

.left-drop-down-important .show.basic.dropdown-menu {
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  transform: translate3d(-162px, 0px, 0px) !important;
}

.custom-progruz-row {
  flex-wrap: wrap;
  row-gap: 10px;
} 

.custom-progruz-row .custom-progruz-row__select {
  min-width: 350px;
}

.custom-progruz-row .custom-progruz-row__buttons {
  max-width: 100%;
  width: auto !important;
}


/* .scrollbar {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.scrollbar-primary-table {
  width: '10px';
  scrollbar-color: #eeeeee #dfdfdf;
}
.scrollbar-primary-table::-webkit-scrollbar {
  width: 10px;
  background-color: #ececec;
}

.scrollbar-primary-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #b4b4b4;
} */

/* .MuiTableCell-root > .MuiTableCell-body {
  text-align: left !important;
} */

/* .md-form.md-outline {
  margin: '0px' !important;
} */