body {
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  color: $black;
  line-height: 1;
  overflow-x: hidden;
  position: relative;
  &:not(.is-modal-open) {
    nav {
      z-index: 10000000;
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .MuiSlider-rail {
    background: #d9e0e5;
    height: 3px;
  }

  .MuiSlider-track {
    background: #5994c4;
    height: 3px;
  }

  .MuiSlider-thumb {
    width: 11px;
    height: 11px;
    background: #fff;
    border: 1px solid #5994c4;
    &.MuiSlider-active,
    &.Mui-focusVisible,
    &:hover {
      box-shadow: 0px 0px 0px 8px rgba(#5994c4, 0.16);
    }
  }
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: #e1e1e3;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.rootcode-progruz {
  .scenarios,
  .scenario {
    width: 100%;
    height: calc(100vh - 49px);
    display: flex;
    flex-direction: column;
    background-color: $blueMystic;
    padding: 30px 0 80px;
    overflow-y: auto;
    overflow-x: hidden;

    &.collapsed {
      width: calc(100vw - 300px);
      transition: 0.15s ease-in-out 0.15s;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $grey;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $black;
    }

    @media (max-width: $mobile) {
      padding: 20px 0 40px;
    }

    .container {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 15px;

      @media (max-width: $pc) {
        max-width: 1310px;
      }

      // @media (max-width: $tablet) {
      //   max-width: 1054px;
      // }

      // @media (max-width: $tabletSmall) {
      //   max-width: 798px;
      // }

      // @media (max-width: $mobile) {
      //   max-width: 100%;
      // }
    }

    a:active,
    a:hover,
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  button {
    border: 0;
    background-color: transparent;
  }

  .hidden {
    display: none !important;
  }

  .invisible {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  .svg-pie {
    height: auto;
    width: 20px;
    enable-background: new 0 0 57.924 57.924;
    margin-right: 10px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 16px;
    font-family: Manrope;
    letter-spacing: -0.48px;
    line-height: 120%; /* 19.2px */
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: 0.2s linear;

    &.white {
      border: 1px solid #e1e8ee;
      color: $darkBlue;
      background-color: $white;
    }

    &.dark {
      background-color: $darkBlue;
    }

    &.blue {
      background-color: $blue;
    }

    &.width {
      width: 105px;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .input,
  .textarea {
    color: $dark;
    font-family: Manrope;
    font-size: 14px;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    padding: 16px 8px;
    background: #edf1f4;
    border-radius: 8px;
    border: none;

    &::placeholder {
      color: $dark;
      font-family: Manrope;
      font-size: 14px;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
    }
  }

  .fill-red {
    fill: #b90606;
  }

  .title-secondary {
    padding-left: 24px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    margin: 0;
    cursor: pointer;
    transition: 0.2s;

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    &__indicator {
      --width: 16px;
      --height: 16px;

      display: flex;
      padding: 2px;

      min-width: var(--width);
      min-height: var(--height);
      width: var(--width);
      height: var(--height);
      border-radius: 4px;
      border: 1px solid $gray;
      background: $white;
    }

    &__label {
      color: #000;
      font-family: Manrope;
      font-size: 15px;
      font-weight: 400;
      line-height: 115%; /* 17.25px */
      letter-spacing: -0.3px;
    }

    &__input {
      display: none;
    }

    &__icon {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.2s;
    }

    &.indeterminate {
      .checkbox__indicator {
        position: relative;
        border-color: $grey;

        display: flex;

        &::before {
          content: '';
          position: absolute;
          width: 72%;
          aspect-ratio: 1/1;
          background: #8bb4d6;
          border-radius: 2px;
        }

        .checkbox__icon {
          opacity: 0;
        }
      }
    }

    &.checked {
      .checkbox__indicator {
        background: #8bb4d6;
        border-color: $grey;
        .checkbox__icon {
          opacity: 1;
        }
      }
    }
  }

  .pagination {
    display: flex;
    gap: 4px;

    font-size: 14px;
    font-family: Manrope;

    &__arrow,
    &__item,
    &__placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      aspect-ratio: 1/1;
      user-select: none;
    }

    &__item {
      min-width: 32px;
      width: auto;
    }

    &__item,
    &__item:active {
      background-color: transparent;
      color: $black;
      border-radius: 4px;
    }

    &__item:hover {
      background-color: #7faed2;
      color: $white;
    }

    &__arrow:hover svg path {
      fill: $grayDark;
    }

    &__arrow:disabled svg path {
      fill: $grey;
    }

    &__item.active,
    &__item:hover.active {
      background-color: $blue;
      color: $white;
    }

    &__placeholder {
      align-self: flex-end;
    }
  }

  .pagination-with-per-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .per-page-input {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 40px;
    font-size: 14px;
    font-family: Manrope;

    & .input {
      width: 80px;
      height: 100%;
      background-color: $white;
      border: 1px solid $gray;
    }
  }

  .year-picker {
    position: relative;
    color: #0d0d0d;
    font-family: Manrope;
    font-weight: 400;
    &__trigger {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      background: $white;
      cursor: pointer;
    }
    &__label {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__icon {
      width: 16px;
      height: 18px;
      color: #a0a0a0;
    }
    &__popup {
      position: absolute;
      top: 100%;
      z-index: 25;
      width: 300px;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.25);
      background: $white;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    &__arrow {
      width: 18px;
      height: 18px;
      &.left {
        margin-right: auto;
        transform: rotate(180deg);
      }
      &.right {
        margin-left: auto;
      }
    }
    &__body {
      display: grid;
      grid-template-columns: repeat(5, 48px);
      gap: 4px;
      justify-content: center;
    }
    &__item {
      font-size: 14px;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      border-radius: 8px;
      transition: 0.2s;
      &.between {
        background: $blueLight;
        color: $blue;
      }
      &.selected {
        background: $blue;
        color: #f9fafc;
      }
      &:hover {
        background: $blueStroke;
        color: $white;
      }
    }
  }

  .input-root {
    display: flex;
    flex-direction: column;
    gap: 8px;

    font-family: Manrope;

    .wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $white;
      padding: 0 16px 0 0;
      border: #f4f4f4;
      border-radius: 8px;
      overflow: hidden;
    }

    &:focus-within .wrapper {
      outline: 2px solid #5994c4;
    }

    .input {
      width: 100%;
      background: transparent;
      padding: 16px;
      outline: none;
    }
  }

  .input-root.error {
    .wrapper,
    .label,
    .input,
    .input::placeholder {
      color: #e36b6c;
    }

    .wrapper {
      outline: 2px solid #e36b6c;
    }
  }

  .loader {
    width: 24px;
    height: 24px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
