// $black: #000000;
$white: #ffffff;
$background: #f9fafc;
$darkBlue: #0e2a3f;
$grey: #89929a;
// $blue: #5994c4;
// $blueLight: #deeaf3;
$blueMystic: #e3e9ee;

$pc: 1400px;
$tablet: 1280px;
$tabletSmall: 1024px;
$mobile: 768px;
$mobileSmall: 375px;

$fontFamily: 'OpenSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
$fontFamilySecond: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

// new color vars

$black: #01091e;
$dark: #0f2850;
$turquoise: #a1b6c7;
$grayDark: #4b6171;
$gray: #d9e0e5;
$red: #e21a1a;
$redLight: #ffe4e6;
$redStroke: #fecdd3;
$blue: #5994c4;
$blueLight: #dbeafe;
$blueStroke: #bfdbfe;
$green: #0abe1c;
$greenLight: #d1fae5;
$greenStroke: #a7f3d0;
