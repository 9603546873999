@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../assets/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../assets/fonts/OpenSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../assets/fonts/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../assets/fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../../assets/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/Manrope-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/Manrope-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/Manrope-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope';
  src: url('../../../assets/fonts/Manrope-Bold.ttf');
  font-weight: 700;
}
