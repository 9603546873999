.scrollbar {
  overflow-y: scroll;
}
.scrollbar-primary {
  scrollbar-color: #374146 #1f292e;
}
.scrollbar-primary::-webkit-scrollbar {
  width: 8px;
  background-color: #1f292e;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4a555a;
}
