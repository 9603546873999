.column {
  min-width: 350px;
}

.menu {
  cursor: pointer;
  transition: background-color;
  transition-property: all;
  transition-duration: 0.4s;
  /* border-left: 7px solid #fe6d2a; */
}

.menu:hover {
  /* background-color: #fe6d2a; */
  background-color: #273238;
  color: #fff;
  border-radius: 4px;
}

.menu:active {
  background-color: #273238;
  color: #fff;
}

.icon {
  color: #ee0909;
  font-size: 23px;
}

.border {
  width: 100%;
  height: 5px;
  border-bottom: 1px solid #b90606;
  margin-bottom: 20px;
  margin-top: 10px;
}

.title {
  /*font-family: 'Halvar Breitschrift Bold';*/
  font-weight: 500 !important;
  font-size: 18px !important;
}

.description {
  white-space: pre-wrap;
  color: #757575;
  font-weight: 400;
}

.menu:hover .description {
  color: #cecece;
}

.disabledmenu {
  transition: background-color;
  transition-property: all;
  transition-duration: 0.4s;
  /* border-left: 7px solid #fe6d2a; */
}

/* .disabledmenu:hover {
  background-color: #ffceb74b;
}

.disabledmenu:active {
  background-color: #585858;
  color: #fff;
} */

.disabledicon {
  color: #ff8f8f;
  font-size: 23px;
}

.disabledborder {
  width: 100%;
  height: 5px;
  border-bottom: 1px solid #fe2a2a;
  margin-bottom: 20px;
  margin-top: 10px;
}

.disabledtitle {
  font-family: 'Halvar Breitschrift Bold';
  color: #949494;
}

.minWidth {
  min-width: 300px;
}
