.header {
  padding: 0.75rem 1.25rem;
  background-color: #263238;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  &__svg {
    margin-right: 0.5rem;
    color: rgb(226, 26, 26)
  }
  &__title {
    font-size: 13px;
    color: #fff;
  }
}